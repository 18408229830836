import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { authRoles } from '../config/roles'

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// Site
const Site = Loadable(lazy(() => import('../views/pages/site/Site')));

// Beta Testing
const BetaTester = Loadable(lazy(() => import('../views/pages/betaTester/BetaTester')));
// dashboard_new
const Notification = Loadable(lazy(() => import('views/pages/notification/Notification')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard/default',
            element: <DashboardDefault />,
            role: authRoles.user,
        },
        {
            path: '/utils/util-sites',
            element: <Site />,
            // role: authRoles.manager,
        },
        {
            path: '/utils/beta-test',
            element: <BetaTester />,
            // role: authRoles.manager,
        },
        {
            path: '/utils/notification',
            element: <Notification />
        }
    ]
};

export default MainRoutes;