import { configureStore } from '@reduxjs/toolkit';

// used for sidebar toggle
import menuSlice from 'features/menu/menuSlice';

// used for global loading indicator
import loaderSlice from 'features/loader/loaderSlice';

// Additional configuration
import authSlice from 'features/auth/authSlice';
import siteSlice from 'features/site/siteSlice';
import betatestSlice from 'features/betaTester/betaTesterSlice';
import notificationSlice from 'features/notification/notificationSlice';

export const store = configureStore({
    reducer: {
        menu: menuSlice,
        loader: loaderSlice,
        auth: authSlice,
        site: siteSlice,
        BetaTester: betatestSlice,
        Notification: notificationSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
})