
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import NotFoundRoutes from './NotFoundRoutes';
import config from 'config';

import { getRoleBasedRoutes } from '../utils';

// ==============================|| ROUTING RENDER ||============================== //


export default function CombineRoutes() {

    let mixedRouter = [AuthenticationRoutes, MainRoutes, NotFoundRoutes];

    let filteredrouter = [AuthenticationRoutes, NotFoundRoutes];

    const encryptedProfile = localStorage.getItem('profile');

    if (encryptedProfile) {
        const user = JSON.parse(Buffer.from(encryptedProfile, "base64").toString());

        if (Object.keys(user).length > 0)
            filteredrouter = getRoleBasedRoutes(mixedRouter, user);
    }

    return useRoutes(filteredrouter, config.basename);
}
