import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from '../../utils/empty-types';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const notificationAsync = createAsyncThunk(
    'notificationAsync',
    async ({ dispatch, type }) => {

        dispatch(loaderStart());

        return await Response('GET_NOTIFICATION', { type })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj)
                    return data?.resultObj

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            });
    }
)

export const saveNotificationAsync = createAsyncThunk(
    'saveNotificationAsync',
    async ({ dispatch, postData }) => {
        console.log('postdata', postData)

        dispatch(loaderStart());

        return await Response('SAVE_NOTIFICATION', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage("Noification saved successfully"));

                    dispatch(notificationAsync({ dispatch, type: "ACTIVE" }));
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));
            })
    }
);

export const removeNotificationAsync = createAsyncThunk(
    'removeNotificationAsync',
    async ({ id, type, dispatch }) => {

        console.log('type', type)

        dispatch(loaderStart());

        return await Response('REMOVE_NOTIFICATION', { id, type })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    if (type  === 'remove'){
                        
                        dispatch(setAlertMessage("Notification Delete successfully"))

                        dispatch(notificationAsync({ dispatch, type: "ACTIVE" }));
                    }

                    if (type === 'recovery'){

                        dispatch(setAlertMessage("Notification Restore successfully"))

                        dispatch(notificationAsync({ dispatch, type: "INACTIVE" }));

                    }

                }

            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(error.message));
            })
    }
);

export const deleteNotificationAsync = createAsyncThunk(
    'deleteNotificationAsync',
    async ({ id, dispatch }) => {

        dispatch(loaderStart());

        return await Response('DELETE_NOTIFICATION', { id })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                        dispatch(setAlertMessage("Notification Deleted Permenantly successfully"))

                        dispatch(notificationAsync({ dispatch, type: "INACTIVE" }));

                }

            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(error.message));
            })
    }
);

export const getSpecificNotificationAsync = createAsyncThunk(
    'getSpecificNotificationAsync',
    async ({ id, dispatch }) => {

        dispatch(loaderStart());

        return await Response('GET_NOTIFICATION_BY_ID', { id })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success && data?.resultObj) {

                    return data?.resultObj
                }

                return {}
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(error.message));

                return {}
            })
    }
);

export const updateNotificationAsync = createAsyncThunk(
    'updateNotificationAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('UPDATE_NOTIFICATION', postData)
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage("Notification Updated successfully"));

                    dispatch(notificationAsync({ dispatch, type: "ACTIVE" }));
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(error.message));
            })

    }

)

export const addImageAsync = createAsyncThunk(
    'addImageAsync',
    async ({ dispatch, postData}) => {

        dispatch(loaderStart());

        return await Response('ADD_IMAGE', postData)
        .then((data) => {

            dispatch(loaderEnd());

            if(data.success && data?.resultObj){

                return data?.resultObj
            }

            return{}
        })

        .catch((error) => {

            dispatch(loaderEnd());

            dispatch(setAlertMessage(error.message));
        })

    }
)

export const sendNotificationAsync = createAsyncThunk('sendNotificationAsync', async ({ dispatch, postData }) => {
    console.log('postdata', postData);

    dispatch(loaderStart());

    return await Response('SEND_NOTIFICATION', postData)
        .then((data) => {
            dispatch(loaderEnd());

            if (data.success) {
                dispatch(setAlertMessage('Noification sent successfully'));
            }
        })
        .catch((error) => {
            dispatch(loaderEnd());

            dispatch(setAlertMessage(getErrorMessage(error.message)));
        });
});

export const notificationSlice = createSlice({
    name: "notification",
    initialState: {

        notificationList: [],

        specificNotificationData: {},

        imageURLData: {},
    },
    reducers: {

        clearNotificationState: (state, { payload }) => {

            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        [notificationAsync.fulfilled]: (state, { payload }) => {

            state.notificationList = payload;
        },

        [getSpecificNotificationAsync.fulfilled]: (state, { payload }) => {

            state.specificNotificationData = payload;
        },

        [addImageAsync.fulfilled]: (state, { payload }) => {

            state.imageURLData = payload;
        },

    }
});

export const { clearNotificationState } = notificationSlice.actions;

export const notificationState = (state) => state.Notification;

export default notificationSlice.reducer;
