import NavList from '../../menu-items';

import Response from '../../utils/response';

import { getSideNavList } from '../menu/menuSlice';

import { getErrorMessage, getRoleBasedRoutes } from 'utils';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const loginAsync = createAsyncThunk(
    'loginAsync',
    async ({ email, password, dispatch }) => {

        dispatch(loaderStart());

        return await Response('LOGIN_USER', { email, password })
            .then((data) => {

                dispatch(loaderEnd());

                if (data.success) {

                    const result = data.resultObj;

                    dispatch(setAlertMessage("Logged In successfully"));

                    const { _id, name, email, role, token } = result;

                    const userData = { _id, name, email, user_type: role };

                    localStorage.setItem('accessToken', token.split(" ")[1]);

                    dispatch(getSideNavList(getRoleBasedRoutes(NavList.items, userData)));

                    return { user: userData, isLoggedIn: true };
                }

                return { user: {}, isLoggedIn: false }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return { user: {}, isLoggedIn: false }
            })
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState: {

        userData: {},

        isLoggedIn: false
    },
    reducers: {

        logout: (state) => {

            state.userData = {},

            state.isLoggedIn = false,

            localStorage.clear();
        }
    },
    extraReducers: {

        [loginAsync.fulfilled]: (state, { payload }) => {

            state.userData = payload.user,

            state.isLoggedIn = payload.isLoggedIn

            const encryptedProfile = Buffer.from(JSON.stringify(payload.user)).toString('base64');

            localStorage.setItem('profile', encryptedProfile);
        }
    }
});

export const { logout } = authSlice.actions;

export const loginState = (state) => state.auth;

export default authSlice.reducer;