/*
Used for Role based route authentication.
*/

export const authRoles = {
    sa: ['SA'],
    admin: ['SA', 'ADMIN'],
    editer: ['SA', 'EDITER'],
    manager: ['SA', 'USER', 'EDITER'],
    user: ['SA', 'ADMIN', 'EDITER', 'USER']
}