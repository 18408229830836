import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import Response from '../../utils/response';
import { getErrorMessage } from 'utils';
import { getEmptyData } from '../../utils/empty-types';

import { loaderStart, loaderEnd, setAlertMessage } from '../loader/loaderSlice';

export const betaTestAsync = createAsyncThunk(
    'betaTestAsync',
    async (dispatch) => {

        dispatch(loaderStart());

        return await Response('BETA_TEST_LIST')
            .then((data) => {

                console.log('data', data)

                dispatch(loaderEnd());

                if (data.success)
                    return data.resultObj

                return []
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));

                return []
            })
    }
)

export const addBetaTestEmailAsync = createAsyncThunk(
    'addBetaTestEmailAsync',
    async ({ dispatch, postData }) => {

        dispatch(loaderStart());

        return await Response('SAVE_EMAIL', postData)
            .then((data) => {
                console.log('SAVE_EMAIL', data)
                dispatch(loaderEnd());

                if (data.success) {

                    dispatch(setAlertMessage("Beta Tester saved successfully"));

                    dispatch(betaTestAsync(dispatch));
                }
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage(getErrorMessage(error.message)));
            })
    }
);

export const removeBetaTestEmailAsync = createAsyncThunk(
    'removeBetaTestEmailAsync',
    async ({email, dispatch}) => {
        console.log("email", email)
        dispatch(loaderStart());

        return await Response('REMOVE_BETATESTER', { email })
        .then((data) => {

            dispatch(loaderEnd());

            if (data.success) {

                dispatch(setAlertMessage("Beta Tester Delete successfully"));

                dispatch(betaTestAsync(dispatch));

                return []
            }

            return []
        })
    }
);

export const betaEmailValidateAsync = createAsyncThunk(
    'betaEmailValidateAsync',
    async ({ email, dispatch }) => {

        dispatch(loaderStart());

        return await Response('BETA_EMAIL_VALIDATE', { email })
            .then((data) => {

                console.log('abc', data)

                dispatch(loaderEnd());

                if (data.success) {

                    const result = data.resultObj;

                    if (!result.isUserExist) {

                        dispatch(setAlertMessage("User Not Exist"));
                        
                    }                    

                    return result.isUserExist;
                }

                return false;
            })
            .catch((error) => {

                dispatch(loaderEnd());

                dispatch(setAlertMessage("User Not exist"));

                return false;
            })
    }
);

export const betaTesterSlice = createSlice({
    name: "BetaTester",
    initialState: {

        betaList: [],
        isUserExist: false,
    },
    reducers: {

        clearBetaTesterState: (state, { payload }) => {

            state[payload.key] = getEmptyData(payload.type);
        }
    },
    extraReducers: {

        [betaTestAsync.fulfilled]: (state, { payload }) => {

            state.betaList = payload

        },

        [betaEmailValidateAsync.fulfilled]: (state, { payload }) => {

            state.isUserExist = payload

        }
    }});

export const { clearBetaTesterState } = betaTesterSlice.actions;

export const betaTestState = (state) => state.BetaTester;

export default betaTesterSlice.reducer;