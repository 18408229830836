/*
*NOTE : key and method name should be in uppercase.

example :  
key : LOGIN_USER
value : method_name + api_endpoint 

method_name : GET POST PUT DELETE

*GET : not need to add method_name

case 1 :
key : GET_STATE
value : api_endpoint

case 2 :
----------------------------------------------------------------

GET api having query string, you have to add a '?' mark in the end

key : GET_STATE
value : api_endpoint?

case 3 :
----------------------------------------------------------------

GET api having query params, you have to add a '#' mark in the end

key : GET_STATE
value : api_endpoint#

*/

export default {
    LOGIN_USER: 'POST /USER/LOGIN',
    BETA_TEST_LIST: '/BETA-TESTER/GET',
    SAVE_EMAIL: 'POST /BETA-TESTER/ADD',
    REMOVE_BETATESTER: 'DELETE /BETA-TESTER/REMOVE?',
    BETA_EMAIL_VALIDATE: '/BETA-TESTER/CHECK?',
    GET_NOTIFICATION: ' /NOTIFICATION/GET?',
    SAVE_NOTIFICATION: 'POST /NOTIFICATION/ADD',
    REMOVE_NOTIFICATION: 'PUT /NOTIFICATION/REMOVE?',
    GET_NOTIFICATION_BY_ID: '/NOTIFICATION/GET_BY_ID?',
    UPDATE_NOTIFICATION: 'POST /NOTIFICATION/UPDATE',
    DELETE_NOTIFICATION: 'DELETE /NOTIFICATION/DELETE?',
    ADD_IMAGE: 'POST /UPLOADER/IMAGE',
    SEND_NOTIFICATION: 'POST /NOTIFICATION/SEND'
};
