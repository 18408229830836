// assets
import { IconBrandDribbble, IconApps , IconUser, IconNotification} from '@tabler/icons';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { authRoles } from 'config/roles';

// constant
const icons = {
    IconBrandDribbble,
    IconApps,
    IconUser,
    IconNotification
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-sites',
            title: 'Beta Tester',
            type: 'item',
            url: '/utils/beta-test',
            icon: icons.IconUser,
            breadcrumbs: true,
            // role: authRoles.manager

        },
        {
            id: 'notification',
            title: 'Notification',
            type: 'item',
            url: '/utils/notification',
            icon: icons.IconNotification,
            breadcrumbs: true,

        }
    ]
};

export default utilities;
